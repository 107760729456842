<template>
  <div class="btc-info-card-box hand" :class="{ active: active }" @click="handleClickCard">
    <div class="img-box">
      <img class="main-img" :src="other.NewsTitlePic || item.NewsTitlePic" />
      <img v-show="Object.keys(other).length > 0 ? !!other.VideoLink : !!item.VideoLink" class="video-img" src="@/assets/image/btcinfo/video.png">
    </div>
    <div class="text-item">
      <p class="NewsTitle" :style="{ width:($route.path === '/BTCInfo/Section'? '420px' : '240px') }">{{ other.NewsTitle || item.NewsTitle }}</p>
      <div class="bottom-box">
        <div class="time">
          <div class="btc-triangle triangle-point-right tc-1" />
          <span>{{ other.NewsDateTime || item.NewsDateTime }}</span>
        </div>
        <div>
          <img v-for="(item, index) in langs" :key="index" :src="item.icon" :alt="item.lang" class="hand" @click.stop="handleChangeLang(item.lang)">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonmix from '@/utils/commonmix'

export default {
  mixins: [commonmix],
  name: 'BTCInfoCard',
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    active: {
      type: Boolean,
      default: false
    },
    isEnExpire: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      other: {},
      langIcon: [
        { lang: 'en', icon: require('@/assets/image/btcinfo/american.png') },
        { lang: 'spanish', icon: require('@/assets/image/btcinfo/spanish.png') },
        { lang: 'portuguese', icon: require('@/assets/image/btcinfo/portuguese.png') }
      ]
    }
  },
  computed: {
    langs () {
      if (this.item && this.item.Langs) {
        const newArr = [...this.item.Langs]
        if (newArr.length !== 0) {
          newArr.unshift('en')
        }
        return this.langIcon.filter(v => {
          return newArr.includes(v.lang)
        })
      }
      return []
    },
    articles () {
      return this.item.Recommendation
    }
  },
  methods: {
    handleClickCard () {
      if (this.$store.getters.token) {
        if (this.isEnExpire) {
          // this.$store.commit('SET_NEWSID', this.item.NewsID)
          // this.$router.push(`/BTCInfo/Article/${this.other.NewsID || this.item.NewsID}`)
          this.$store.commit('SET_NEWSID', this.item.NewsID)
          // 跳转新界面
          const path = `/BTCInfo/Article/${this.other.NewsID || this.item.NewsID}`
          const routeData = this.$router.resolve({ path: path, query: { rid: this.item.NewsID } })
          const open = window.open(routeData.href, '_blank')
          if (open === null || typeof (open) === 'undefined') {
            this.$router.push(path)
          }
        } else {
          this.openMembershipModal()
        }
      } else {
        this.openLoginModal()
      }
    },
    handleChangeLang (lang) {
      if (this.$store.getters.token) {
        if (this.isEnExpire) {
          if (lang === 'en') {
            this.other = {}
          } else {
            this.other = this.articles.filter(v => {
              return v.NewsLanguageCode === lang
            })[0]
          }
        } else {
          this.openMembershipModal()
        }
      } else {
        this.openLoginModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$height: 73px;

.btc-info-card-box {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
.btc-info-card-box > .img-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btc-info-card-box > .img-box > .main-img {
  width: 120px;
  height: 20px;
  height: $height;
  border-radius: 3px;
  // background-color: teal;
}
.btc-info-card-box > .img-box > .video-img {
  width: 30px;
  position: absolute;
  top: 0;
  left: 0;
}
.text-item {
  flex: 1;
  height: $height;
  min-width: 140px;
  padding: 0 10px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bottom-box {
  display: flex;
  justify-content: space-between;
}

.bottom-box > div > img {
  width: 30px;
  height: 20px;
  margin-left: 10px;
}

.bottom-box > .time {
  display: flex;
  align-items: center;
}

.bottom-box > .active {
  background: #e0e0e0;
}

.NewsTitle{
  line-height: 21px;
  margin-bottom: 5px;
  font-size: 14px;
  width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical
}

</style>
